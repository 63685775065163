import React from 'react';
import logo from './aura.png';
import telegram_bot from './telegram_bot.png';
import circles from './circles.png';
import instagram from './instagram.png';
import tiktok from './tiktok.png';

const SimpleLandingPage = () => {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen items-center bg-dark-gradient" style={{ width: 'w-64', height: 'w-64'}}>
      {/* Rotating Circles */}
      <img
        src={circles}
        alt="Rotating Circles"
        className="absolute animate-spin-slow object-cover"
        style={{ width: '100%', height: '100%', zIndex: 1}}
      />
      {/* Content */}
      <div className="relative z-20 flex flex-col items-center justify-center">
        {/* Logo */}
        <div>
          <img src={logo} alt="Aura Logo" className="w-64 h-auto mb-8" />
        </div>
        <div>
          <h1 className="text-4xl text-white font-semibold mb-1 text-center">Build up your aura</h1>
          <h1 className="text-4xl font-semibold mb-16 text-center" style={{ color: '#a73ada'}}>Take her on a Date</h1>
        </div>
        {/* Telegram Icon */}
        <div>
          <a
            href="https://t.me/AuraAIApp_bot"
            target="_blank"
            rel="noopener noreferrer"
            className="relative z-20"
          >
            <img
              src={telegram_bot}
              alt="Telegram Bot"
              className="w-32 h-32"
            />
          </a>
        </div>
      </div>


      {/* Footer */}
      <footer className="py-6 z-20">
        <div className="flex flex-col items-center">
          <p className="text-white text-lg mb-4">Social Media</p>
          <div className="flex space-x-4">
            <a
              href="https://instagram.com/aura.ai.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" className="w-8 h-8" />
            </a>
            <a
              href="https://tiktok.com/@heartignite.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tiktok} alt="TikTok" className="w-8 h-8" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SimpleLandingPage;

